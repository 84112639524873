/** @format */

import React from "react";
import { styled } from "@mui/material/styles";
import { getUsername } from "../../utils/auth";
import Button from "@mui/material/Button";

const StyledHeader = styled("div")({
	display: "flex",
	width: "100%",
	justifyContent: "flex-end",
	alignItems: "center",
	padding: "0 20px 10px",
	boxSizing: "border-box",
});

const StyledUsername = styled("p")({
	marginRight: "10px",
});

const Header = () => {
	const username = getUsername();
	const logout = () => {
		localStorage.removeItem("jwtToken");
		window.location.reload();
	};
	return (
		<StyledHeader>
			<StyledUsername>Ulogovani korisnik: {username}</StyledUsername>
			<Button
				variant="contained"
				onClick={logout}
				sx={{
					background: "orange",
					"&:hover": {
						backgroundColor: "#e68b02",
						boxShadow: "none",
					},
					boxShadow: "none",
					height: "40px",
				}}
			>
				Izloguj se
			</Button>
		</StyledHeader>
	);
};

export default Header;
