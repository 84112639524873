/** @format */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useSubmitFormDataMutation } from "../../api/mainApi";
import { useSnackbarNotification } from "../../components_shared/snackbar-notification/SnackbarNotificationContext";
import RHFDropdownVirtualized from "./RHFDropdownVirtualized";
import Header from "./Header";
import RHFTextarea from "./RHFTextarea";
import GroupImageCompressAndUpload from "./GroupImageCompressAndUpload";
import GroupImagePreview from "./GroupImagePreview";
import DocumentUpload from "./DocumentUpload";
import ProductImageCompressAndUpload from "./ProductImageCompressAndUpload";
import Button from "@mui/material/Button";
import VerticalSeparator from "./VerticalSeparator";
import ProductImagesPreview from "./ProductImagesPreview";
import ProductsList from "./ProductsList";
import "../../App.css";

const StyledWrapper = styled("div")({
	display: "flex",
	justifyContent: "center",
	width: "-webkit-fill-available",
});

const StyledLeftPlatformPanel = styled("div")({
	border: "1px solid orange",
	borderRight: "none",
	// flex: 1,
	width: "50%",
	height: "80vh",
	overflow: "auto",
	padding: "10px",
	marginLeft: "20px",
	position: "relative",
});

const StyledRightPlatformPanel = styled("div")(() => ({
	border: "1px solid orange",
	// flex: 1,
	width: "50%",
	height: "80vh",
	padding: "10px",
	display: "flex",
	marginRight: "20px",

	"@media (max-width: 1015px)": {
		flexDirection: "column",
	},
}));

const StyledProductList = styled("div")(({ theme }) => ({
	padding: "0 10px 0 0",
	// border: "1px solid red",
	borderRight: "1px solid orange",
	width: "100%",
	height: "100%",
	boxSizing: "border-box",
	overflow: "auto",
	[theme.breakpoints.down(1016)]: {
		borderRight: 0,
		// or use 'unset' if you prefer
		// borderRight: 'unset',
	},
}));

const StyledProductImageList = styled("div")({
	padding: "0 0 0 10px",
	// border: "1px solid red",
	width: "100%",
	height: "100%",
	boxSizing: "border-box",
	overflow: "auto",
});

export default function Dashboard() {
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedProductImages, setSelectedProductImages] = useState([]);
	const [selectedGroupId, setSelectedGroupId] = useState(null);
	const [isDisabled, setIsDisabled] = useState(true);

	const { showSnackbarNotification } = useSnackbarNotification();

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm();
	// const [submitFormData, { isLoading, isSuccess, isError, error }] = useSubmitFormDataMutation();
	const [submitFormData] = useSubmitFormDataMutation();

	// TODO: This is here to remind me that a defaultValues object can be passed to useForm
	// const defaultValues = {
	// 	selectedGroupId: "",
	// 	textarea: "",
	// 	groupImage: null,
	// };

	// Set the selected group to the form state to be able to send it to the API
	const handleSetSelectedGroup = (selectedGroup) => {
		if (selectedGroup && selectedGroup.value) {
			setValue("selectedGroupId", selectedGroup.value);
			setSelectedGroupId(selectedGroup.value);
			setSelectedProductImages([]);
		}
	};

	// Set the selected image to the state to be able to send it to the GroupImagePreview component
	const handleSetGroupImageSelect = (image) => {
		setSelectedImage(image);
	};

	// Set the updated group image name to the form state to be able to send it to the API
	const handleSetNameChangedGroupImage = (nameChangedGroupImage) => {
		// Handle the updated file received from the GroupImagePreview component
		setValue("groupImage", nameChangedGroupImage);
	};

	// Set the selected document to the form state to be able to send it to the API
	const handleSetDocumentSelect = (document) => {
		setValue("document", document);
	};

	const handleSetProductImagesSelect = (productImages) => {
		setSelectedProductImages((prevImages) => {
			const newImages = productImages.filter(
				(newImage) => !prevImages.some((existingImage) => existingImage.name === newImage.name),
			);
			return [...prevImages, ...newImages];
		});
	};

	const handleRemoveDroppedImage = (index) => {
		const newProductImages = selectedProductImages.filter((_, i) => i !== index);
		setSelectedProductImages(newProductImages);
	};

	// Add default values to the form, so that it can be RESET after submission
	const defaultValues = {
		selectedGroupId: "",
		textarea: "",
		groupImage: null,
		document: null,
		productImages: [],
	};

	const onSubmit = async (data) => {
		try {
			const formData = new FormData();

			// Group Id
			formData.append("selectedGroupId", data.selectedGroupId);

			// Textarea
			formData.append("textarea", data.textarea);

			// Group Image
			if (data.groupImage) {
				formData.append("groupImage", data.groupImage);
			}

			// Document
			if (data.document && Array.isArray(data.document)) {
				data.document.forEach((doc, index) => {
					formData.append(`document[${index}][file]`, doc.file);
					formData.append(`document[${index}][name]`, doc.name);
				});
			}

			if (data.productImages && Object.keys(data.productImages).length > 0) {
				Object.entries(data.productImages).forEach(([key, file]) => {
					formData.append(`productImages[${key}]`, file);
				});
			}

			// Submit your data
			await submitFormData(formData).unwrap();

			showSnackbarNotification("Podaci uspešno poslati.");

			// Reset form fields
			reset(defaultValues);

			// Reset local state
			setSelectedImage(null);
			setSelectedProductImages([]);
			setSelectedGroupId(null);
			setIsDisabled(true);

			// // Mock server URL for testing
			// const response = await fetch("https://httpbin.org/post", {
			// 	method: "POST",
			// 	body: formData,
			// });

			// const result = await response.json();
			// console.log("Response from server:", result);
		} catch (error) {
			console.error("Failed to submit form data", error);
			showSnackbarNotification(
				`Došlo je do greške prilikom kreiranja porudžbine: ${error.message}`,
			);
		}
	};

	// enable the fields after the selectedGroupId is selected
	useEffect(() => {
		watch("selectedGroupId") && setIsDisabled(false);
		// remove error message from selectedGroupId
		errors.selectedGroupId = null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("selectedGroupId")]);

	// TODO: This is just a guide on how to populate the form fields with existing data
	// * Idea is to send a request to the API upon selecting a group and populate the form fields with the existing data
	useEffect(() => {
		const existingData = {
			textarea: "Previously entered text for EDIT functionality...",
		};
		if (existingData) {
			setValue("textarea", existingData.textarea);
		}
	}, [setValue]);

	return (
		<div className="white-bg-black-text">
			<Header />
			<StyledWrapper>
				<StyledLeftPlatformPanel>
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* GROUP DROPDOWN */}
						<RHFDropdownVirtualized
							{...register("selectedGroupId", { required: "Morate izabrati grupu proizvoda" })}
							onChange={handleSetSelectedGroup}
						/>
						{errors.selectedGroupId && (
							<p style={{ color: "red" }}>
								<i>{errors.selectedGroupId.message}</i>
							</p>
						)}
						{/* SEPARATOR */}
						<VerticalSeparator size="40px" />
						{/* GROUP DESCRIPTION */}
						<RHFTextarea
							{...register("textarea", { required: "Morate uneti opis grupe proizvoda" })}
							disabled={isDisabled}
						/>
						{errors.textarea && (
							<p style={{ color: "red" }}>
								<i>{errors.textarea.message}</i>
							</p>
						)}
						{/* SEPARATOR */}
						<VerticalSeparator size="40px" />
						{/* GROUP IMAGE UPLOAD */}
						<GroupImageCompressAndUpload
							onImageSelect={handleSetGroupImageSelect}
							disabled={isDisabled}
						/>
						<GroupImagePreview
							selectedImage={selectedImage}
							selectedGroupId={watch("selectedGroupId")}
							onSave={handleSetNameChangedGroupImage}
						/>
						{errors.groupImage && (
							<p style={{ color: "red" }}>
								<i>{errors.groupImage.message}</i>
							</p>
						)}
						{/* SEPARATOR */}
						<VerticalSeparator size="40px" />
						{/* DOCUMENT UPLOAD */}
						<DocumentUpload
							{...register("document")}
							onDocumentReady={handleSetDocumentSelect}
							selectedGroupId={watch("selectedGroupId")}
							disabled={isDisabled}
						/>
						<p style={{ color: "red" }}>{errors.document && errors.document.message}</p>
						{/* SEPARATOR */}
						<VerticalSeparator size="40px" />
						{/* PRODUCT IMAGES UPLOAD */}
						<ProductImageCompressAndUpload
							onProductImagesSelect={handleSetProductImagesSelect}
							disabled={isDisabled}
						/>
						{errors.productImages && (
							<p style={{ color: "red" }}>
								<i>{errors.productImages.message}</i>
							</p>
						)}
						{/* SEPARATOR */}
						<VerticalSeparator size="40px" />
						<Button
							type="submit"
							variant="contained"
							disableElevation
							sx={{
								height: "40px",
								background: "orange",
								"&:hover": {
									backgroundColor: "#e68b02",
									boxShadow: "none",
								},
								boxShadow: "none",
								position: "absolute",
								bottom: "10px",
								left: "10px",
							}}
						>
							Prosledi formu
						</Button>
					</form>
				</StyledLeftPlatformPanel>
				<StyledRightPlatformPanel>
					<StyledProductList>
						<ProductsList
							{...register("productImages", { required: "Morate izabrati fotografije proizvoda" })}
							selectedGroupId={selectedGroupId}
							setValue={setValue}
						/>
					</StyledProductList>
					<StyledProductImageList>
						<>
							Lista slika proizvoda
							<ProductImagesPreview
								productImages={selectedProductImages}
								removeDroppedImage={handleRemoveDroppedImage}
								selectedGroupId={selectedGroupId}
							/>
						</>
					</StyledProductImageList>
				</StyledRightPlatformPanel>
			</StyledWrapper>
		</div>
	);
}
