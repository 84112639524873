/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { getRole } from "../../utils/auth.js";
// * Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../../utils/MRT_Localization_SR_LATN_RS.js";
import { SideDetailsContext } from "../../contexts_shared/SideDetailsContext.js";
// import { formatAddress, formatCurrency } from "../shared/utils/formatting.js";
import { formatCurrency } from "../../shared/utils/formatting.js";
import { totalValue } from "../../shared/utils/totals.js";
import { formatDate } from "../../shared/utils/formatting.js";
import { useGetOrdersQuery, useUpdateOrderMutation } from "../../api/mainApi.js";
import {
	LoadingNotificationBox,
	ErrorNotificationBox,
} from "../../shared/styled/NotificationBox.js";
import { SideDetailsAnimateContext } from "../../contexts_shared/SideDetailsAnimateContext.js";
import OrderStatusDropdown from "./OrderStatusDropdown.jsx";
import Chip from "@mui/material/Chip";
import { getSerbianStatus, getEnglishStatus, getStatusColor } from "./utils.js";

// simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example
// Material React Table docs: https://www.material-react-table.com/docs/getting-started/install

const OrdersTable = () => {
	const { animateSideDetailsIn } = React.useContext(SideDetailsAnimateContext);
	const { data: rows, isLoading, isError, isSuccess } = useGetOrdersQuery();
	const [updateOrder] = useUpdateOrderMutation();
	const { setContextValue } = React.useContext(SideDetailsContext);
	const [clickedRowId, setClickedRowId] = React.useState(null);
	const isAdmin = getRole() === "admin";

	React.useEffect(() => {
		const horizontal_scroll = document.querySelector(".ch-table-container");
		isSuccess && horizontal_scroll.children[1].classList.add("scroll-handler");
	}, [isSuccess]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorFn: (row) => formatDate(row.dateTimeStamp),
				id: "dateTimeStamp",
				header: "Datum porudžbine",
				// Due to the fact that the date is a string, we need to convert it to a Date object to be able to sort it correctly
				sortingFn: (rowA, rowB) => {
					return new Date(rowA.original.dateTimeStamp) - new Date(rowB.original.dateTimeStamp);
				},
			},
			{
				accessorKey: "buyer",
				header: "Kupac",
			},
			{
				accessorKey: "address",
				id: "address",
				header: "Adresa isporuke",
			},
			{
				accessorKey: "status",
				header: "Status",
				size: 150,
				Cell: ({ cell, row, table }) => {
					const status = getEnglishStatus(cell.getValue());
					const color = getStatusColor(status);

					return isAdmin ? (
						<OrderStatusDropdown
							value={status}
							onChange={(newValue) => {
								table.options.meta?.updateData(row.index, "status", newValue);
							}}
						/>
					) : (
						<Chip
							label={getSerbianStatus(status)}
							color={color}
							style={{ width: "150px" }}
						/>
					);
				},
				accessorFn: (row) => getSerbianStatus(row.status),
			},
			{
				accessorFn: (row) => formatCurrency(Number(row.price)),
				id: "price",
				header: "Vrednost",
				size: 90,
			},
		],
		[isAdmin],
	);

	// function buyerAddress(streetName, streetNumber, city) {
	// 	if (streetNumber === null || streetNumber === "" || streetNumber === undefined) {
	// 		return `${streetName}, ${city}`;
	// 	}
	// 	return `${streetName} ${streetNumber}, ${city}`;
	// }
	// function zipCity(zipCode, city) {
	// 	if (zipCode === null || zipCode === "" || zipCode === undefined) {
	// 		return `${city}`;
	// 	}
	// 	return `${zipCode} ${city}`;
	// }

	const handleTableRowClick = React.useCallback(
		(row) => {
			// ! Session storage is used for PDF Export and for total value in OrderDetails,
			// TODO: Session storage needs to be removed and instead RTK Query cache should be used
			sessionStorage.setItem("order_id", row.original.id);
			sessionStorage.setItem("dateTimeStamp", row.original.dateTimeStamp);
			sessionStorage.setItem("buyer_address", row.original.address);
			sessionStorage.setItem("buyer_name", row.original.buyer);
			// sessionStorage.setItem(
			// 	"street_name_number",
			// 	`${row.original.streetName} ${row.original.streetNumber ?? ""}`,
			// );
			// sessionStorage.setItem("zip_city", zipCity(row.original.zipCode, row.original.city));
			sessionStorage.setItem("order", JSON.stringify(row.original.order.body));
			// ! let's figure out how to set this to the context or send it as a prop to OrderDetails
			sessionStorage.setItem("total_value", formatCurrency(totalValue(row.original.order.body)));
			setContextValue(row.original); // ! This is the whole data object of the clicked row
			setContextValue((prev) => ({
				...prev,
				total_value: formatCurrency(totalValue(row.original.order.body)),
			}));
			setClickedRowId(row.id);

			// open side details on click on any OrderTable row
			animateSideDetailsIn();
		},
		[animateSideDetailsIn, setContextValue],
	);

	// TODO: check how to change the logic of a clicked row! Maybe Material Table has something in the docs about COLORING the SELECTED row
	const rowClassName = (rowId) => {
		return rowId === clickedRowId ? "clicked-row" : "";
	};

	const handleStatusChange = async (rowIndex, newStatus) => {
		const orderToUpdate = rows[rowIndex];
		try {
			await updateOrder({ id: orderToUpdate.id, status: newStatus }).unwrap();
			// The query will be automatically refetched due to invalidatesTags
		} catch (error) {
			console.error("Failed to update order:", error);
		}
	};

	if (isLoading) {
		// render a loading indicator here
		return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	}

	if (isError) {
		// render an error message here
		return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	}

	return (
		<MaterialReactTable
			localization={MRT_Localization_SR_LATN_RS}
			columns={columns}
			data={rows}
			initialState={{
				sorting: [
					{
						id: "dateTimeStamp",
						desc: true,
					},
				],
				columnVisibility: { id: false },
			}}
			muiTablePaperProps={{
				// change the mui box shadow
				elevation: 0,
				// customize paper styles
				sx: {
					borderRadius: "0",
					// borderTop: '1px solid #e0e0e0',
					borderBottom: "1px solid #e0e0e0",
					cursor: "pointer",
				},
				className: "ch-table-container",
			}}
			enableColumnResizing
			columnResizeMode="onChange"
			muiTableBodyRowProps={({ row }) => ({
				onClick: () => handleTableRowClick(row), // TODO: consider implementing class animate-side-details here, instead in App.js
				className: rowClassName(row.id),
			})}
			meta={{
				updateData: (rowIndex, columnId, value) => {
					if (columnId === "status") {
						handleStatusChange(rowIndex, value);
					}
				},
				// updateData: async (rowIndex, columnId, value) => {
				// 	const orderToUpdate = rows[rowIndex];
				// 	try {
				// 		await updateOrder({ id: orderToUpdate.id, [columnId]: value }).unwrap();
				// 		// Optionally show a success message here
				// 	} catch (error) {
				// 		// Handle any errors, maybe show an error message to the user
				// 		console.error("Failed to update order:", error);
				// 	}
				// },
			}}
		/>
	);
};

export default OrdersTable;
