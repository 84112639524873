/** @format */

import { useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ProductsContext } from "../contexts_shared/ProductsContext";
import { useGetProductsQuery } from "../api/mainApi";
import ProductsTable from "./ProductsTable";

const StyledProductsPage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	/* justify-content: center; */
	height: 100%;
	width: 100%;
	color: black;
	/* border: 1px solid red; */
	box-sizing: border-box;
	// difference of 300px (1800px - 1500px) is needed to account for the sidebar width (to prevent the jump in size of the products page element)
	// I do not know what is this for. I do not understand the comment above. I will remove it.
	// It probably worked with header hiding logic, but now I think it behaves strange if left uncommented.
	/* @media screen and (min-width: 1800px) {
		width: 1500px;
	} */
`;

const ProductTableWrapper = styled.div`
	z-index: 1;
	overflow: auto;
`;

const StyledImageTitleDescription = styled.div`
	display: flex;
	/* border: 1px solid green; */
	border-bottom: 1px solid lightgray;
	border-radius: 15px;
	box-sizing: border-box;
	@media screen and (max-width: 1033px) {
		flex-direction: column;
	}
	background-color: white;
	position: sticky;
	top: 0;
	z-index: 1; // TODO: Check if this causes any problems in future. For now, it's needed to prevent the table from overlapping when in full screen mode, the image and title
`;

const StyledWrapper = styled.div`
	/* border: 5px solid yellow; */
	width: 50%;
	padding: 1rem;
	@media screen and (max-width: 1033px) {
		width: auto;
	}
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const BACKEND_ROOT_PATH = "https://backend-b2b.central-ch.com/public/product_files/";

const ProductsPage = ({ showOrderModal, setShowOrderModal }) => {
	const { state } = useLocation();
	const { id } = useParams();
	const { setGroupID } = useContext(ProductsContext);
	// We use isSuccess here to trigger the query on refreshing the page
	// TODO: Check if there is a better way to do this
	const { isSuccess } = useGetProductsQuery(id);

	useEffect(() => {
		setGroupID(id);
	}, [id, setGroupID]);

	if (!state || !isSuccess) {
		return <div>Loading...</div>;
	}

	return (
		<StyledProductsPage>
			<StyledImageTitleDescription>
				<StyledWrapper>
					<StyledImage
						src={BACKEND_ROOT_PATH + state.group_photo}
						alt="Slika proizvoda"
						onError={(e) => {
							e.target.src = "https://watermark.lovepik.com/photo/40008/0007.jpg_wh1200.jpg";
							e.target.style.maxWidth = "500px";
						}}
					/>
				</StyledWrapper>
				<StyledWrapper>
					<h1>{state.name}</h1>
					<pre
						style={{
							whiteSpace: "pre-wrap",
							wordWrap: "break-word",
							fontSize: "16px",
							fontFamily: "Roboto, sans-serif",
						}}
					>
						{state.description}
					</pre>
				</StyledWrapper>
			</StyledImageTitleDescription>
			<ProductTableWrapper>
				<ProductsTable
					showOrderModal={showOrderModal}
					setShowOrderModal={setShowOrderModal}
				/>
			</ProductTableWrapper>
		</StyledProductsPage>
	);
};

export default ProductsPage;
