/** @format */

import { useEffect, useMemo, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import MaterialReactTable from "material-react-table";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getRole } from "../utils/auth.js";
import { useOrderModal } from "./orders/OrderModalContext";
import Modal from "@mui/material/Modal";
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";
import { useGetProductsQuery } from "../api/mainApi.js";
import { ProductsContext } from "../contexts_shared/ProductsContext.js";
import OrderModal from "./orders/OrderModal";

const ModalImageWrapper = styled(Box)({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: "none",
	outline: "none",
});

const Pipe = styled("span")({
	color: "red",
});

const formatPrice = (price) => {
	return new Intl.NumberFormat("sr-RS", {
		style: "currency",
		currency: "RSD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(price);
};

const ProductsTable = () => {
	const { groupID } = useContext(ProductsContext);
	const { data: rows, isSuccess } = useGetProductsQuery(groupID);
	const [tableData, setTableData] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [showOrderModal, setShowOrderModal] = useState(false);
	const { shouldOpenModal, setShouldOpenModal, updateOrderData, resetOrder, orderData } =
		useOrderModal();

	const isAdmin = getRole() === "admin";

	useEffect(() => {
		if (shouldOpenModal) {
			setShowOrderModal(true);
			setShouldOpenModal(false);
		}
	}, [shouldOpenModal, setShouldOpenModal]);

	useEffect(() => {
		if (rows?.length) {
			const filteredRows = rows.filter((row) => row.vp_price && parseFloat(row.vp_price) > 0);

			// Merge the existing order data (if any) with the new group data
			const mergedRows = filteredRows.map((product) => {
				const existingOrder = orderData.orderItems.find((item) => item.id === product.id);
				return existingOrder
					? { ...product, order_quantity: existingOrder.order_quantity }
					: product;
			});

			setTableData(mergedRows);
		}
	}, [rows, groupID, orderData]);

	useEffect(() => {
		const horizontal_scroll = document.querySelector(".ch-table-container");
		if (isSuccess && horizontal_scroll && horizontal_scroll.parentElement) {
			horizontal_scroll.parentElement.classList.add("scroll-handler");
		}
	}, [isSuccess]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 60,
				enableEditing: false,
			},
			{
				accessorKey: "product_image",
				header: "",
				size: 50,
				enableSorting: false,
				enableEditing: false,
				Cell: ({ row }) => (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							width: "50px",
							height: "50px",
						}}
					>
						<img
							src={row.original.product_image}
							alt=""
							onClick={() => handleImageClick(row.original.product_image)}
							style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "cover" }}
						/>
					</Box>
				),
			},
			{
				// accessorFn: (row) => console.log("these functions are used for computed values"),
				accessorKey: "product_name",
				header: "Naziv proizvoda",
				enableEditing: false,
			},
			{
				accessorKey: "vp_price",
				header: "VP cena",
				size: 100,
				enableEditing: false,
				Cell: ({ cell }) => formatPrice(cell.getValue()),
			},
			{
				accessorKey: "rabat",
				header: "Rabat",
				size: 100,
				enableEditing: false,
			},
			{
				accessorKey: "net_price",
				header: "Neto cena",
				size: 100,
				enableEditing: false,
				Cell: ({ cell }) => formatPrice(cell.getValue()),
			},
			// Conditionally include the order_quantity column for non-admin users
			...(!isAdmin
				? [
						{
							accessorKey: "order_quantity",
							header: "Porudžbina",
							size: 70,
							enableEditing: true,
						},
				  ]
				: []),
		],
		[isAdmin],
	);

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const handleCloseModal = () => {
		setSelectedImage(null);
	};

	const handleSaveCell = (cell, value) => {
		const row = JSON.parse(JSON.stringify(tableData[cell.row.index]));
		row.order_quantity = value;
		const newData = [...tableData];
		newData[cell.row.index] = row;
		setTableData(newData);

		updateOrderData({
			orderItems: newData,
			totalWithoutPDV: newData.reduce((sum, item) => sum + item.order_quantity * item.net_price, 0),
		});
	};

	// if (isLoading) {
	// 	// render a loading indicator here
	// 	return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	// }

	// if (isError) {
	// 	// render an error message here
	// 	return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	// }

	return (
		<>
			{isSuccess && tableData.length > 0 ? (
				<MaterialReactTable
					localization={MRT_Localization_SR_LATN_RS}
					columns={columns}
					data={tableData}
					editingMode="cell"
					enableColumnResizing
					columnResizeMode="onChange"
					muiTableBodyCellEditTextFieldProps={({ cell }) => ({
						// onChange can be used here, but onBlur is more efficient
						onBlur: (event) => {
							handleSaveCell(cell, event.target.value);
						},
					})}
					renderBottomToolbarCustomActions={() =>
						!isAdmin ? (
							<Typography
								sx={{ fontStyle: "italic", p: "0 1rem" }}
								variant="body2"
								component="div"
							>
								<p style={{ margin: 0, padding: 0 }}>
									<Pipe sx={{ fontSize: "1.5em", marginRight: "0.5em", fontWeight: "bold" }}>
										&#124;
									</Pipe>
									<span>
										Dupli klik na polje kolone "Porudžbina" za unos količine, potom <b>Enter</b> ili
										klik van polja za potvrdu.
									</span>
								</p>
								<p style={{ margin: 0, padding: 0 }}>
									<Pipe sx={{ fontSize: "1.5em", marginRight: "0.5em", fontWeight: "bold" }}>
										&#124;
									</Pipe>
									<span>
										Potom klik na korpu u gornjem desnom uglu za pregled i potvrdu porudžbine.
									</span>
								</p>
							</Typography>
						) : (
							<p></p> // return an empty paragraph for admin users to avoid rendering pagination on the left side of the table
						)
					}
					muiTablePaperProps={{
						elevation: 0,
						sx: {
							borderRadius: "0",
							borderBottom: "1px solid #e0e0e0",
							cursor: "pointer",
						},
						className: "ch-table-container",
					}}
				/>
			) : (
				<Typography
					variant="h6"
					style={{ textAlign: "center", marginTop: "20px", color: "black" }}
				>
					{isSuccess ? "Nema proizvoda sa važećim cenama za prikaz." : "Učitavanje proizvoda..."}
				</Typography>
			)}
			<Modal
				open={!!selectedImage}
				onClose={handleCloseModal}
			>
				<ModalImageWrapper>
					{selectedImage && (
						<img
							src={selectedImage}
							alt=""
						/>
					)}
				</ModalImageWrapper>
			</Modal>
			<OrderModal
				open={showOrderModal}
				handleClose={() => setShowOrderModal(false)}
				handleCancelOrder={resetOrder}
				{...orderData}
			/>
		</>
	);
};

export default ProductsTable;
