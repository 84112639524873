import { jwtDecode } from "jwt-decode";


export const decodeToken = (token) => {
	try {
		return jwtDecode(token);
	} catch (error) {
		console.error('Failed to decode token', error);
		return null;
	}
};

export const getRole = () => {
	const token = localStorage.getItem('jwtToken');
	if (!token) return null;

	const decoded = decodeToken(token);
	return decoded ? decoded.role : null;
};

export const getBuyerId = () => {
	const token = localStorage.getItem('jwtToken');
	if (!token) return null;

	const decoded = decodeToken(token);
	return decoded ? decoded.buyer_id : null;
};

export const getUsername = () => {
	const token = localStorage.getItem('jwtToken');
	if (!token) return null;

	const decoded = decodeToken(token);
	return decoded ? decoded.username : null;
};
